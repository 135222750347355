import * as React from 'react';

const Busca = (): JSX.Element => {
    return (
        <form
            action="/busca"
            method={'GET'}
            className={'border flex rounded border-gray-light bg-white items-center'}>
            <input
                name="q"
                type="text"
                placeholder={'Buscar...'}
                className={'border-none w-full inline-hover focus:border-blue-main focus:ring-0'}
            />
            <button className={'px-2 inline-hover text-gray-main hover:text-blue-main'}>
                <svg
                    aria-hidden="true"
                    data-icon="search"
                    xmlns="http://www.w3.org/2000/svg"
                    className={'w-4 h-4'}
                    viewBox="0 0 512 512">
                    <path
                        fill="currentColor"
                        d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"
                    />
                </svg>
            </button>
        </form>
    );
};

export default Busca;
